import $ from 'jquery';
import AOS from 'aos';

$(document).ready(function() {

    /* MATCH HEIGHT EQUAL HEIGHT

    $('.matchHeight').matchHeight();
     */

    /* SWITCHER
    $('.toggle-btn').click(function() {
        $(this).parent().toggleClass('active').siblings().removeClass('active');
        //$(this).parent().find('.single_switcher__active').toggleClass('active').siblings().removeClass('active');
    });
     */

    /* AOS ANIMATION FUNCTION

    $(function() {
        AOS.init();
    });
     */

	var maxHeight = 0;
	$(".cf_all_news_single > div").each(function(){
		if ($(this).height() > maxHeight) { maxHeight = $(this).height(); }
	});
	$(".cf_all_news_single > div").height(maxHeight);

	$(".helpdesk_partners_single > div").each(function(){
		if ($(this).height() > maxHeight) { maxHeight = $(this).height(); }
	});
	$(".helpdesk_partners_single > div").height(maxHeight);

	$(".helpdesk_estimate_single .content_box_shadow").each(function(){
		if ($(this).height() > maxHeight) { maxHeight = $(this).height(); }
	});
	$(".helpdesk_estimate_single .content_box_shadow").height(maxHeight);

	$('input').on('input', function() {
		if ($(this).val()) {
			$(this).addClass('filled');
		} else {
			$(this).removeClass('filled');
		}
	});

	/*
	$(window).on("load", function() {
		// Calculez les hauteurs initiales
		setMaxHeight();

		// Mettez à jour les hauteurs lors du redimensionnement de la fenêtre
		$(window).resize(function(){
			setMaxHeight();
		});
	});

	function setMaxHeight() {
		// Obtenir la hauteur de la sidebar
		var sidebarHeight = $('.sidebar').outerHeight();
		// Obtenir la hauteur du contenu
		var contentHeight = $('.get_height').outerHeight();
		// Trouver la plus grande hauteur
		var maxHeight = Math.max(sidebarHeight, contentHeight);
		// Appliquer cette hauteur à l'élément cible
		$('.cf_height').height(maxHeight);
	}
	 */

});